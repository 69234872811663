const Strings = {
  About: {
    Title: "About",
  },
  Skillset: {
    Title: "Skillset",
  },
  Books: {
    Title: "Books",
    Description: "Some of the books I've read in the last 20 years.",
  },
  Blog: {
    Title: "Blog",
  },
  Contact: {
    Title: "Contact",
  },
  Tags: {
    Title: "Search results",
    All: "All tags",
  },
  Home: {
    Title: "Blog",
  },
  Snippet: {
    Title: "Read more",
    Ellipses: "... ",
  },
  Nav: {
    Instagram: "Instagram",
    Twitter: "Twitter",
  },
  Recent: {
    Title: "Recent posts",
  },
  Footer: {
    Copyright: "©",
    RightsReserved: "Kurt McIntire. All Rights Reserved.",
  },
  Missing: {
    Title: "404: Not Found",
    Message: "Hey, Kurt here. Looks like that page is missing.",
  },
}

export default Strings
