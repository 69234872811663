import React from "react"
import NavLink from "./NavLink"
import Strings from "../constants/Strings"
import Endpoints from "../constants/Endpoints"
import "./OverlayMenu.css"

const OverlayMenu = menuPress => (
  <header
    onClick={menuPress}
    className="overlayMenuCollapse"
    style={styles.overlayMenu}
  >
    <NavLink dark to={Endpoints.home} screenStyles={styles.menuItem}>
      {Strings.Home.Title.toUpperCase()}
    </NavLink>
    <NavLink dark to={Endpoints.skillset} screenStyles={styles.menuItem}>
      {Strings.Skillset.Title.toUpperCase()}
    </NavLink>
    <NavLink dark to={Endpoints.books} screenStyles={styles.menuItem}>
      {Strings.Books.Title.toUpperCase()}
    </NavLink>
    <NavLink dark to={Endpoints.contact} screenStyles={styles.menuItem}>
      {Strings.Contact.Title.toUpperCase()}
    </NavLink>
  </header>
)

const navItemStyle = {
  menuText: {
    fontSize: "1.2rem",
  },
  menuContainer: {
    marginBottom: "20px",
  },
}

const styles = {
  menuItem: {
    ...navItemStyle.menuText,
    ...navItemStyle.menuContainer,
  },
  overlayMenu: {
    backgroundColor: "white",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    position: "fixed",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
    height: "100%",
  },
}

export default OverlayMenu
