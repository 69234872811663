import Colors from "../constants/Colors"

const StyleFooterLink = {
  light: {
    color: Colors.white,
  },
  dark: {
    color: Colors.gray2,
  },
  text: {
    fontSize: "0.7rem",
  },
  link: {
    textDecoration: `none`,
    marginLeft: "15px",
    fontSize: "0.6rem",
    fontWeight: 500,
    fontStyle: "regular",
    marginBottom: "0px",
    letterSpacing: "1px",
  },
}

export default StyleFooterLink
