import React from "react"
import { Link } from "gatsby"
import LogoIcon from "./LogoIcon"
import Strings from "../constants/Strings"
import Endpoints from "../constants/Endpoints"
import Slash from "./Slash"
import NavLink from "./NavLink"
import Hamburger from "./Hamburger"
import "./Navigation.css"
import "./Link.css"

const LOGO_DIM = 46

const Navigation = ({ menuPress }) => (
  <header className="nav, marginStandard" style={styles.headerBackground}>
    <Link style={styles.logoHeight}>
      <LogoIcon width={LOGO_DIM} />
    </Link>
    <Hamburger screenStyle={styles.float} onClick={menuPress} />
    <NavLink dark to={Endpoints.skillset} collapses>
      {Strings.Skillset.Title.toUpperCase()}
    </NavLink>
    <Slash dark collapses />
    <NavLink dark to={Endpoints.books} collapses>
      {Strings.Books.Title.toUpperCase()}
    </NavLink>
    <Slash dark collapses />
    <NavLink dark to={Endpoints.contact} collapses>
      {Strings.Contact.Title.toUpperCase()}
    </NavLink>
  </header>
)

const styles = {
  headerBackground: {
    zIndex: 2,
    marginBottom: `1.0rem`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderBottom: "solid 1px #E6E7E8",
  },
  float: {
    marginLeft: "auto",
  },
  logoHeight: {
    height: LOGO_DIM,
  },
}

export default Navigation
