import React from "react"
import Strings from "./../constants/Strings"
import Colors from "../constants/Colors"
import Endpoints from "../constants/Endpoints"
import Slash from "./Slash"
import NavLink from "./NavLink"
import "./Layout.css"
import "./Link.css"
import "./Navigation.css"

const Footer = () => (
  <footer style={styles.footer}>
    <div className="marginStandard">
      <div style={styles.linkContainer}>
        <NavLink to={Endpoints.home} screenStyles={{ marginLeft: 0 }}>
          {Strings.Home.Title.toUpperCase()}
        </NavLink>
        <Slash collapses />
        <NavLink to={Endpoints.skillset}>
          {Strings.Skillset.Title.toUpperCase()}
        </NavLink>
        <Slash collapses />
        <NavLink to={Endpoints.books}>
          {Strings.Books.Title.toUpperCase()}
        </NavLink>
        <Slash collapses />
        <NavLink to={Endpoints.contact}>
          {Strings.Contact.Title.toUpperCase()}
        </NavLink>
      </div>
      <p style={styles.text}>{yearString()}</p>
    </div>
  </footer>
)

const yearString = () => {
  const d = new Date()
  const year = d.getFullYear()
  return `${Strings.Footer.Copyright} ${year} ${Strings.Footer.RightsReserved}`
}

const styles = {
  footer: {
    backgroundColor: Colors.black,
    marginTop: "auto",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  text: {
    color: "white",
    fontSize: "0.7rem",
  },
  link: {
    textDecoration: `none`,
    marginLeft: "15px",
    fontSize: "0.6rem",
    fontWeight: 500,
    fontStyle: "regular",
    marginBottom: "0px",
    letterSpacing: "1px",
  },
  linkContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}

export default Footer
