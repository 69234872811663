import React, { useState } from "react"
import "./Hamburger.css"

const Hamburger = ({ screenStyle, onClick }) => {
  const [isActive, setActive] = useState(false)
  const className = isActive
    ? "hamburger hamburger--collapse is-active"
    : "hamburger hamburger--collapse"

  return (
    <div onClick={onClick} style={{ ...styles.box, ...screenStyle }}>
      <button
        className={className}
        type="button"
        onClick={() => setActive(!isActive)}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    </div>
  )
}

const styles = {
  box: {
    display: "flex",
  },
}

export default Hamburger
