import React from "react"
import Colors from "../constants/Colors"

const LogoIcon = ({ width }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 200 200" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <circle
          id="Oval"
          fill={Colors.black}
          cx="100"
          cy="100"
          r="100"
        ></circle>
        <g
          id="Group"
          transform="translate(-38.000000, 57.000000)"
          fill={Colors.white}
          font-family="Interstate-Bold, Interstate"
          font-size="72"
          font-weight="bold"
        >
          <text id="K" letter-spacing="-12">
            <tspan x="73.08" y="69">
              K
            </tspan>
          </text>
          <text id="M" letter-spacing="-12">
            <tspan x="112.94" y="69">
              M
            </tspan>
          </text>
          <text id="c" letter-spacing="-10">
            <tspan x="161.668" y="69">
              c
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  )
}

export default LogoIcon
