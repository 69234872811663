import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Strings from "../constants/Strings"

const NotFoundPage = () => (
  <Layout override="marginStandard">
    <SEO title={Strings.Missing.Title} />
    <h1>{Strings.Missing.Title}</h1>
    <p>{Strings.Missing.Message}</p>
  </Layout>
)

export default NotFoundPage
