import React, { useState } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Footer from "./Footer"
import Navigation from "./Navigation"
import OverlayMenu from "./OverlayMenu"
import "./Link.css"
import "./Layout.css"

const Layout = ({ children, override }) => {
  const [showMenu, setMenu] = useState(false)

  const menuPress = () => {
    setMenu(!showMenu)
  }

  const renderSite = () => (
    <div className="site" style={styles.zeroIndex}>
      <div className={override}>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )

  const renderMenu = menuPress => {
    return (
      <div>
        <OverlayMenu onPress={menuPress} />
        {renderSite()}
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className="site">
          <Navigation menuPress={menuPress} />
          {showMenu ? renderMenu() : renderSite()}
        </div>
      )}
    />
  )
}

const styles = {
  zeroIndex: { zIndex: 0 },
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
