import React from "react"
import { Link } from "gatsby"
import "./Link.css"
import StyleFooterLink from "../styles/StyleFooterLink"

const NavLink = ({ to, dark, collapses, screenStyles, children }) => {
  const textColor = dark ? StyleFooterLink.dark : StyleFooterLink.light
  const className = collapses ? "link, collapses" : "link"
  return (
    <Link
      className={className}
      to={to}
      style={{
        textDecoration: `none`,
        ...StyleFooterLink.text,
        ...StyleFooterLink.link,
        ...textColor,
        ...screenStyles,
      }}
    >
      {children}
    </Link>
  )
}

export default NavLink
