import React from "react"
import "./Navigation.css"
import StyleFooterLink from "../styles/StyleFooterLink"

const Slash = ({ dark, collapses }) => {
  const textColor = dark ? StyleFooterLink.dark : StyleFooterLink.light
  const className = collapses ? "collapses" : null
  return (
    <p
      className={className}
      style={{
        ...StyleFooterLink.text,
        ...StyleFooterLink.link,
        ...textColor,
      }}
    >
      /
    </p>
  )
}

export default Slash
